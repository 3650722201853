<template>
    <div class="login">
        <div class="wrapper">
            <form @submit.prevent="login" class="form">
                <Input v-if="$route.name == 'AdminLogin'" v-model="username" name="email" type="email" :required="true" icon="user" class="form-input"/>
                <Input v-model="password" name="password" type="password" :required="true" icon="lock" class="form-input"/>
                <Collapse :collapsed="!error.active" @end-close="error.message = ''">
                    <div class="error">{{ error.message }}</div>
                </Collapse>
                <div class="input-group">
                    <button class="form-submit" type="submit">Login</button>
                </div>
            </form>
            <div class="alt-login">
                <router-link :to="{name: $route.name == 'Login' ? 'AdminLogin' : 'Login'}">{{ $route.name == 'Login' ? 'Admin login' : 'Guest login' }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Collapse from '@/components/Collapse'
import Input from '@/components/Input'

export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            error: {
                active: false,
                message: ''
            }
        }
    },
    components: {
        Collapse,
        Input
    },
    methods: {
        login() {
            const next = this.$route.query.next
            if (this.$route.name == 'AdminLogin') {
                this.$auth.loginAdmin('admin/login/', this.username, this.password)
                .then(() => {
                    next ? this.$router.push({path: next}) : this.$router.push({name: 'Upload'})
                })
                .catch(err => {
                    this.notifyError(err.response.data.message)
                })
            }
            else {
                this.$auth.login('login/', this.password)
                .then(() => {
                    next ? this.$router.push({path: next}) : this.$router.push({name: 'Welcome'})
                })
                .catch(err => {
                    this.notifyError(err.response.data.message)
                })
            }
        },
        notifyError(message) {
            this.error.message = message
            this.$nextTick(() => this.error.active = true)
            setTimeout(() => {
                this.error.active = false
            }, 5000)
        }
    }
}
</script>

<style scoped>
.login {
    height: calc(100vh - 3.5rem);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper {
    width: 512px;
    min-height: 256px;
    border: 2px solid darkgreen;
    border-radius: 1rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    text-align: center;
    padding: 2rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
}
.form {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}
.form-input {
    margin-bottom: 1rem;
}
.error {
    background-color: pink;
    border: 1px solid red;
    border-radius: .25rem;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: .8rem;
}
.form-submit {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #98ff98;
    padding: .375rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    cursor: pointer;
    background-color: #cfffe5;
    color: green;
}
.alt-login {
    font-size: .5rem;
    text-decoration: none;
}
.alt-login>a {
    color: #bbb;
    text-decoration: none;
}
</style>