<template>
    <div class="input-group">
        <div v-if="icon" class="input-prepend">
            <span class="input-icon">
                <FontAwesomeIcon :icon="icon"/>
            </span>
        </div>
        <input :value="value" :name="name" :type="type" :required="required" @input="$emit('input', $event.target.value)" class="input">
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'InputText',
    props: {
        value: String,
        name: String,
        type: String,
        required: Boolean,
        icon: String
    },
    components: {
        FontAwesomeIcon
    }
}
</script>

<style scoped>
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-prepend {
    display: flex;
    margin-right: -1px;
}
.input-icon {
    display: flex;
    padding: .25rem .75rem;
    line-height: 1;
    text-align: center;
    align-items: center;
    background-color: #eee;
    border: 1px solid gray;
    border-radius: .5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input {
    margin: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: .5rem;
    transition: box-shadow .15s ease-in-out;
    flex: 1 1 auto;
    width: 0;
}
.input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input:focus {
    outline: 0;
    box-shadow: 0 0 .3rem .2rem #cfffe5;
}
</style>